import './viewport.js';
import './style.js';
import amiiboF from './amiibo.js';

import jszip from 'jszip';
import ready from 'document-ready-promise';
import escaperegexp from 'lodash.escaperegexp';

const amiibo = amiiboF();

/// [[api field, header text, sort order, filter text]]
const attributes = [
  ["type", "Amiibo Type", 1, ""],
  ["amiiboSeries", "Amiibo Series", 1, ""],
  ["name", "Amiibo Name", 1, ""],
  ["gameSeries", "Game Series", 1, ""],
  ["character", "Cha\u00ADrac\u00ADter Name", 1, ""],
  ["add", "Down\u00ADload?", -1, null],
  ["image", "Picture", null, null]
];
/// api field => index in above array
const idc = {};
attributes.forEach((attr, i) => idc[attr[0]] = i);

const buttons = [
  ["Invert selection", transformSelection(chk => chk.click())],
  ["Reset selection", transformSelection(chk => chk.checked = false)],
  ["Reset filters", resetFilters],
  ["Download selection", downloadAmiibos]
].map(([text, clicker]) => {
  const button = document.createElement("button");
  button.type = "button";
  button.textContent = text;
  button.onclick = clicker;
  return button;
});

function transformSelection(trafo) {
  return () => [...tbody.rows]
    .map(row => row.cells[idc["add"]].getElementsByTagName("INPUT")[0])
    .forEach(trafo);
}

function resetFilters() {
  thead.querySelectorAll("input[type='text']").forEach(input => input.value = "");
  attributes.forEach(attr => attr[3] = "");
  filterTable();
}

function downloadAmiibos() {
  const zip = new jszip();
  const zipdl = document.createElement("a");
  zipdl.download = "amiibos.zip";
  zipdl.target = "_blank";
  const selected = [...tbody.rows]
    .filter(row => row.cells[idc["add"]].getElementsByTagName("INPUT")[0].checked);
  progt.textContent = "Compiling amiibo files…";
  prog.value = 0;
  prog.max = selected.length;
  progl.replaceChild(prog, actions);
  const now = new Date();
  const common = {
    lastWriteDate: now.toISOString().substring(0, 10),
    writeCounter: 0,
    version: 0
  };
  selected
    .map(row => [row.getAttribute("data-head") + row.getAttribute("data-tail"), row.cells[idc["name"]].textContent])
    .forEach(([amiiboId, name]) => {
      name = `${name} - ${amiiboId}`;
      const uuidArray = new Uint8Array(10);
      window.crypto.getRandomValues(uuidArray);
      const uuid = Array.from(uuidArray).map(i => i.toString(16)).join('').toUpperCase();
      const dir = zip.folder(`emuiibo/amiibo/${name}`);
      const files = {
        common,
        model : {amiiboId},
        register: {
          name,
          firstWriteDate: common.lastWriteDate,
          miiCharInfo: "mii-charinfo.bin"
        },
        tag: {uuid}
      };
      Object.entries(files).forEach(([k, v]) => dir.file(`${k}.json`, JSON.stringify(v)));
      prog.value += 1;
    });
  progt.textContent = "Generating zip file…";
  prog.value = 0;
  prog.max = 100;
  zip
    .generateAsync({type: "base64"}, ({percent, currentFile}) => (prog.value = percent))
    .then(b64 => {
      prog.removeAttribute("value");
      progt.textContent = "Downloading zip file…"
      zipdl.href = "data:application/zip;base64," + b64;
      zipdl.click();
  }).catch(error => (progt.textContent = error))
    .then(() => progl.replaceChild(actions, prog));
}

function rowComp(rs, idx) {
  const cs = rs.map(r => r.cells[idx]);
  if (attributes[idx][0] === "add") {
    const vs = cs.map(c => c.getElementsByTagName("INPUT")[0].checked);
    return vs[0] - vs[1];
  } else {
    const vs = cs.map(c => c.textContent);
    return vs[0].localeCompare(vs[1]);
  }
}

function loadImage(event) {
  if (event.target.checked) {
    const img = event.target.parentElement.parentElement.cells[idc["image"]].getElementsByTagName("IMG")[0];
    img.src = img.getAttribute("data-src");
    img.removeAttribute("data-src");
    event.target.removeEventListener("change", loadImage);
  }
}

function selectByImage(event) {
  event.target.parentElement.parentElement.cells[idc["add"]].getElementsByTagName("input")[0].click();
}

function filterByAttribute(event) {
  const filter = hrow.cells[[...event.target.parentElement.parentElement.cells].indexOf(event.target.parentElement)].getElementsByTagName("INPUT")[0];
  filter.value = `^${escaperegexp(event.target.textContent)}$`;
  filter.dispatchEvent(new Event('input'));
}

function amiiboToRow(obj) {
  const row = document.createElement("tr");
  row.setAttribute("data-head", obj.head);
  row.setAttribute("data-tail", obj.tail);
  attributes.forEach(([k,]) => {
    const cell = document.createElement("td");
    let content;
    switch (k) {
      case "image":
        content = document.createElement("img");
        content.setAttribute("data-src", obj[k]);
        content.addEventListener("click", selectByImage);
        content.alt = "Select to load image";
        break;
      case "add":
        content = document.createElement("input");
        content.type = "checkbox";
        content.addEventListener("change", loadImage);
        break;
      default:
        content = document.createElement("p");
        content.textContent = obj[k];
        content.addEventListener("click", filterByAttribute);
    }
    cell.appendChild(content);
    row.appendChild(cell);
  });
  prog.value += 1;
  return row;
}

function filterTable(idx, filter) {
  if (idx !== undefined) {
    try {
      attributes[idx][3] = filter && new RegExp(filter, 'i');
    } catch (e) {
      return;
    }
  }
  const nbody = document.createElement("tbody");
  trows
    .filter(row => attributes.every(([, , , f, ], i) => (!f || row.cells[i].textContent.match(f))))
    .forEach(nbody.appendChild.bind(nbody));
  table.replaceChild(nbody, tbody);
  tbody = nbody;
}

function sortTable(idc) {
  idc = Array.isArray(idc) ? idc : [idc];
  idc.forEach(idx => {
    const order = attributes[idx][2];
    trows.sort((r0, r1) => order * rowComp([r0, r1], idx));
    attributes[idx][2] = -order;
  });
  filterTable();
}

const table = document.createElement("table");
const thead = document.createElement("thead");
const hrow = document.createElement("tr");
attributes.forEach(([k, v, o, f,], idx) => {
  const cell = document.createElement("th");
  const text = document.createElement("p")
  text.textContent = v;
  if (o) {
    text.onclick = () => sortTable(idx);
  }
  cell.appendChild(text);
  if (f !== null) {
    const filter = document.createElement("input");
    filter.type = "text";
    filter.placeholder = "RegExp";
    filter.value = f;
    filter.oninput = () => filterTable(idx, filter.value)
    cell.appendChild(filter);
  }
  hrow.appendChild(cell);
});
thead.appendChild(hrow);
table.appendChild(thead);

let tbody = document.createElement("tbody");
table.appendChild(tbody);
const trows = [];

const progl = document.createElement("label");
const progt = document.createElement("p")
progt.textContent = "Retrieving amiibo data…";
const prog = document.createElement("progress");
progl.appendChild(progt);
progl.appendChild(prog);
const actions = document.createElement("div");
buttons.forEach(button => actions.appendChild(button));
ready().then(() => {
  document.body.appendChild(progl);
  document.body.appendChild(table);
});

amiibo.then(amiibos => {
  progt.textContent = "Processing amiibo data…";
  prog.max = amiibos.length;
  prog.value = 0;
  trows.push(...amiibos.map(amiiboToRow));
  sortTable([0, 2, 4, 1]);
  progt.textContent = "Filter or sort by attributes (try clicking on one) and select amiibos for download!";
  progl.replaceChild(actions, prog);
}).catch(error => progl.textContent = error);
